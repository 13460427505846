exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-app-ios-tsx": () => import("./../../../src/pages/app-ios.tsx" /* webpackChunkName: "component---src-pages-app-ios-tsx" */),
  "component---src-pages-cloud-terminal-index-jsx": () => import("./../../../src/pages/cloud-terminal/index.jsx" /* webpackChunkName: "component---src-pages-cloud-terminal-index-jsx" */),
  "component---src-pages-cooperation-agent-tsx": () => import("./../../../src/pages/cooperation/agent.tsx" /* webpackChunkName: "component---src-pages-cooperation-agent-tsx" */),
  "component---src-pages-cooperation-eco-partner-tsx": () => import("./../../../src/pages/cooperation/eco-partner.tsx" /* webpackChunkName: "component---src-pages-cooperation-eco-partner-tsx" */),
  "component---src-pages-cooperation-index-tsx": () => import("./../../../src/pages/cooperation/index.tsx" /* webpackChunkName: "component---src-pages-cooperation-index-tsx" */),
  "component---src-pages-download-index-tsx": () => import("./../../../src/pages/download/index.tsx" /* webpackChunkName: "component---src-pages-download-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-launch-app-tsx": () => import("./../../../src/pages/launch-app.tsx" /* webpackChunkName: "component---src-pages-launch-app-tsx" */),
  "component---src-pages-open-index-tsx": () => import("./../../../src/pages/open/index.tsx" /* webpackChunkName: "component---src-pages-open-index-tsx" */),
  "component---src-pages-pricing-index-tsx": () => import("./../../../src/pages/pricing/index.tsx" /* webpackChunkName: "component---src-pages-pricing-index-tsx" */),
  "component---src-pages-product-index-tsx": () => import("./../../../src/pages/product/index.tsx" /* webpackChunkName: "component---src-pages-product-index-tsx" */),
  "component---src-pages-product-personal-tsx": () => import("./../../../src/pages/product/personal.tsx" /* webpackChunkName: "component---src-pages-product-personal-tsx" */),
  "component---src-pages-product-private-tsx": () => import("./../../../src/pages/product/private.tsx" /* webpackChunkName: "component---src-pages-product-private-tsx" */),
  "component---src-pages-product-public-tsx": () => import("./../../../src/pages/product/public.tsx" /* webpackChunkName: "component---src-pages-product-public-tsx" */),
  "component---src-pages-solutions-education-training-tsx": () => import("./../../../src/pages/solutions/education-training.tsx" /* webpackChunkName: "component---src-pages-solutions-education-training-tsx" */),
  "component---src-pages-solutions-film-television-tsx": () => import("./../../../src/pages/solutions/film-television.tsx" /* webpackChunkName: "component---src-pages-solutions-film-television-tsx" */),
  "component---src-pages-solutions-government-enterprise-tsx": () => import("./../../../src/pages/solutions/government-enterprise.tsx" /* webpackChunkName: "component---src-pages-solutions-government-enterprise-tsx" */),
  "component---src-pages-solutions-qjc-solution-tsx": () => import("./../../../src/pages/solutions/qjc-solution.tsx" /* webpackChunkName: "component---src-pages-solutions-qjc-solution-tsx" */),
  "component---src-pages-solutions-visual-design-tsx": () => import("./../../../src/pages/solutions/visual-design.tsx" /* webpackChunkName: "component---src-pages-solutions-visual-design-tsx" */),
  "component---src-pages-trigger-deploy-tsx": () => import("./../../../src/pages/trigger-deploy.tsx" /* webpackChunkName: "component---src-pages-trigger-deploy-tsx" */)
}

